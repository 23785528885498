import React from 'react';

import Layout from '../components/layout';

const IndexPage: React.FC = ():JSX.Element => (
  <Layout>
    <h1>Hi there</h1>
    <p>This site is under construction. I mean, you can poke around, it&apos;s just slightly empty.</p>
  </Layout>

);

export default IndexPage;
